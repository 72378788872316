import { Button, Icon } from "nzk-react-components"
import React, { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import useScroll from "../../../../../../../hooks/useScroll"
import { Steps, useBookCreatorState } from "../../index.state"
import * as s from "../index.styles"
// @ts-ignore
import DedicationImage from "./dedication.png"

const Header = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 25px;
  color: #662d91;
`

const BackButton = styled.div`
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

const Content = styled.div`
  color: #662d91;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  justify-content: center;
`

const Section = styled.section`
  .title {
    margin-bottom: 12px;
    span {
      margin-left: 10px;
      font-size: 12px;
      color: #000;
    }
  }
`

const PagePreview = styled.div`
  background-color: #ebebeb;
  border-radius: 15px;
  .thumbnail {
    position: relative;
    width: 100%;
    > :first-child {
      width: 100%;
      height: 280px;
    }
    > :last-child {
      position: absolute;
      top: 33%;
      transform: translateX(-50%);
      left: 50%;
      font-size: 6.5px;
      width: 90%;
      text-align: center;
      max-width: 88px;
      margin: 0 auto;
    }
  }
`

const Form = styled.div`
  border-radius: 15px;
  p {
    font-size: 14px;
    color: #000;
  }
`

const PagePreviewSection = styled(Section)`
  width: 40%;
  margin-right: 30px;
  min-width: 280px;
  @media (max-width: 740px) {
    margin-bottom: 30px;
    margin-right: 0;
  }
`

const FormSection = styled(Section)`
  width: calc(60% - 30px);
  @media (max-width: 740px) {
    max-width: 400px;
    width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  justify-content: space-between;
  color: #662d91;
  font-size: 14px;
  margin-top: 20px;
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  height: 200px;
  border-radius: 12px;
  border: 2px solid #ccc;
  padding: 25px 30px;
  outline: none;
  text-align: center;
  font-family: "Libre Baskerville";
`

const Dedication = () => {
  const MAX_LENGTH = 160
  const MAX_LINES = 8

  const { data, setCurrentStep, updateData } = useBookCreatorState()
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const { scrollToElement } = useScroll()

  useEffect(() => {
    if (!data.animalImg) setCurrentStep(Steps.CHOOSE_ANIMAL)
    if (!data.customMessage) {
      updateData({
        customMessage:
          "We hope you love this book as much as we love the character you created!",
      })
    }
  }, [])

  const charactersRemaining = useMemo(() => {
    return (
      MAX_LENGTH -
      (data.customMessage || "").split("\n").reduce((acc, sentence) => {
        if (sentence.length > 20) {
          return acc + Math.floor(sentence.length / 20) * 20
        }
        if (sentence.length < 20) {
          return acc + 20
        }
        return acc
      }, 0)
    )
  }, [data])

  const linesRemaining = useMemo(() => {
    return charactersRemaining / 20
  }, [charactersRemaining])

  const canSubmit = useMemo(() => {
    return linesRemaining >= 0
  }, [data])

  const onSave = () => {
    if (canSubmit) {
      setCurrentStep(Steps.PREVIEW)
    }
  }

  const onPreviewClicked = () => {
    if (!textAreaRef.current) return
    scrollToElement(textAreaRef.current)
    const end = (textAreaRef.current.value || "").length
    textAreaRef.current.focus()
    textAreaRef.current.selectionStart = end
  }

  return (
    <s.Step>
      <s.StepContent>
        <Header>
          <BackButton>
            <Button
              round
              theme="primary"
              size="x-small"
              onClick={() => setCurrentStep(Steps.ANIMAL_NAME)}
            >
              <Icon name="arrow-left" />
            </Button>
            Animal Details
          </BackButton>
        </Header>

        <Content>
          <PagePreviewSection>
            <div className="title">Dedication Preview</div>
            <PagePreview onClick={onPreviewClicked}>
              <div className="thumbnail">
                <img src={DedicationImage} alt="Cover" />
                <div
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: (data.customMessage || "").replace(/\n/g, "<br />"),
                  }}
                />
              </div>
            </PagePreview>
          </PagePreviewSection>
          <FormSection>
            <div className="title">
              Dedication Text
              <span>({MAX_LINES} lines limit)</span>
            </div>
            <Form>
              <p>
                We'll print this on the last page, exactly as you've written it.
              </p>
              <TextArea
                ref={textAreaRef}
                value={data.customMessage}
                onChange={e => updateData({ customMessage: e.target.value })}
              />
              <Action>
                <div>{linesRemaining} lines remaining</div>
                <Button
                  theme="confirm"
                  size="small"
                  disabled={!canSubmit}
                  onClick={onSave}
                >
                  Next
                </Button>
              </Action>
            </Form>
          </FormSection>
        </Content>
      </s.StepContent>
    </s.Step>
  )
}

export default Dedication
