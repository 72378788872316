import * as React from "react"
import BookCreator from "./components/BookCreator"

const IndexPage = () => {
  return (
    <>
      <BookCreator />
    </>
  )
}

export default IndexPage
