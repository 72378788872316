import React from "react"

export default () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 961.71 36.08"
      style={{
        position: "absolute",
        bottom: "0",
        transform: "translateY(100%)",
      }}
    >
      <defs />
      <path
        className="cls-1"
        fill="#c6c6c6"
        d="M59,1.83C116.24,6.45,235.25,17,304.79,29.44,395.84,45.78,488,39.5,534.22,1.83Z"
        transform="translate(-59.04 -1.83)"
      />
      <path
        className="cls-2"
        fill="#c6c6c6"
        d="M1020.75,1.83C963.56,6.45,844.55,17,775,29.44,684,45.78,591.77,39.5,545.58,1.83Z"
        transform="translate(-59.04 -1.83)"
      />
    </svg>
  )
}
