import { Button, Icon } from "nzk-react-components"
import React, { useEffect } from "react"
import styled from "styled-components"
import { TAnimalType } from "."

const Wrapper = styled.div`
  position: relative;
  max-width: 300px;
  min-height: 450px;
  background-color: #fff;
  box-shadow: 0 4px 0 #afafaf, 0 7px 0 rgba(0, 0, 0, 0.4);
  color: #662d91;
  border-radius: 16px;
  text-align: center;
  padding: 15px 20px;
  .title {
    font-size: 34px;
  }
  .info {
    font-size: 11px;
  }
  .close-button {
    position: absolute;
    top: -10px;
    right: -10px;
  }
`

const Section = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  .section--title {
    color: #6ba546;
    font-size: 13px;
    margin-bottom: 11px;
  }

  .section--options {
    > * {
      margin-bottom: 14px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

interface IProps {
  dismiss?: () => void
  onSelected: (type: keyof TAnimalType) => void
}

const ChangeSelectionModal = (props: IProps) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <Wrapper
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div className="close-button">
        <Button round theme="red" size="x-small" onClick={props.dismiss}>
          <Icon name="close" />
        </Button>
      </div>
      <div className="title">Change your animal</div>
      <div className="info">
        If you have previously drawn an animal select 'Draw online' to edit it.
      </div>
      <Section>
        <div className="section--title">Existing Night Zookeeper</div>
        <div className="section--options">
          <Button
            theme="confirm"
            size="small"
            onClick={() => props.onSelected("NZK")}
          >
            Login & Select
          </Button>
        </div>
      </Section>
      <Section>
        <div className="section--options">
          <Button
            theme="primary"
            size="small"
            onClick={() => props.onSelected("DRAWING")}
          >
            Draw online
          </Button>
          <Button
            theme="primary"
            size="small"
            onClick={() => props.onSelected("UPLOAD")}
          >
            Upload drawing
          </Button>
          <Button
            theme="primary"
            size="small"
            onClick={() => props.onSelected("LIBRARY")}
          >
            From our library
          </Button>
        </div>
      </Section>
    </Wrapper>
  )
}

ChangeSelectionModal.defaultProps = {
  dismiss: () => {},
}

export default ChangeSelectionModal
