import React, { useState } from "react"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import {
  useCloudinary,
  Modal
} from "nzk-react-components"
import {
  DrawingTool as NzkDrawingTool,
  useDrawingTool as useNzkDrawingTool
} from "@wonkystar/drawing-tool"
import Button from "components/UI/Button"
import Loader from "components/UI/Loader"
import { useBookCreatorState } from "../../index.state"
// @ts-ignore
import CoverBlank from "./images/cover-blank.jpg"

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
`

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100%;
  background-image: url("${CoverBlank}");
  background-size: cover;
  background-position: center;
  overflow: hidden;
`

const LoaderWrapper = styled.div`
  background-color: rgba(0,0,0,.4);
  height: 100%;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

interface Props {
  onBack: () => void
  openUploadOnStart?: boolean
}

const Drawing = (props: Props) => {
  const { onAnimalImage } = useBookCreatorState()
  const { exportSketch, imageToCrop, imageToPlace } = useNzkDrawingTool()
  const { editing } = useBookCreatorState()

  const { uploadImage } = useCloudinary({
    uploadTag: "POD_BOOK"
  })

  const [saving, setSaving] = useState(false)
  const [noImageWarning, setNoImageWarning] = useState(false)
  const [smallImageWarning, setSmallImageWarning] = useState(false)

  const onNoImage = () => {
    setSaving(false)
    setNoImageWarning(true)
  }

  const onSave = (force = false) => {
    if (saving || imageToCrop || imageToPlace) return

    setSaving(true)

    let b64ImageString: string

    try {
      b64ImageString = exportSketch({ crop: true })
    } catch (err) {
      console.log(err)
      onNoImage()
      return
    }

    if (!b64ImageString) {
      onNoImage()
      return
    }

    // check size and upload
    const img = new Image()
    img.onload = () => {
      if (!force && Math.max(img.width,img.height) < 200) {
        setSaving(false)
        setSmallImageWarning(true)
      } else {
        // TODO: update hook on react-components to handle erros so we can show an error message if there is a problem
        uploadImage(b64ImageString, imageUrl => {
          setSaving(false)
          onAnimalImage(imageUrl)
        })
      }
    }
    img.src = b64ImageString
  }

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768

  return (
    <Wrapper>
      <Div100vh>
        <Container>
          <NzkDrawingTool
            cacheKey={editing && `${editing}-drawing-cache` || undefined}
            openUploadPopupOnStart={props.openUploadOnStart}
            prompt="Draw your hero"
            onSave={onSave}
            onBack={props.onBack}
            minImageUploadSize={1024 * 25}
            enableMagicCrop
            magicCropUploadPreset="aapgcmds"
          />
          { smallImageWarning && <LoaderWrapper>
            <Modal actions={[
              <Button key='try-again' theme='purple' size={isMobile ? 'small' : 'regular'} onClick={() => setSmallImageWarning(false)}>Try again</Button>,
              <Button key='next' theme='confirm' size={isMobile ? 'small' : 'regular'} onClick={() => { setSmallImageWarning(false); onSave(true) }}>Continue</Button>
            ]} title="This drawing is very small. A larger drawing will get better results." dismiss={() => setSmallImageWarning(false)}/>
          </LoaderWrapper> }
          { noImageWarning && <LoaderWrapper>
            <Modal actions={[
              <Button key='ok' theme='primary' size={isMobile ? 'small' : 'regular'} onClick={() => setNoImageWarning(false)}>Ok</Button>
            ]} title="Please draw or upload an animal to continue to the next step." dismiss={() => setNoImageWarning(false)}/>
          </LoaderWrapper> }
          { saving && <LoaderWrapper>
            <Loader color="#FFF" placeholder="Saving Drawing..." />
          </LoaderWrapper>}
        </Container>
      </Div100vh>
    </Wrapper>
  )
}

Drawing.defaultProps = {
  openUploadOnStart: false,
}

export default Drawing
