import Loader from "components/UI/Loader"
import React from "react"
import styled from "styled-components"
import { useZookeeperSelectionState } from "../index.state"
import Animal from "./Animal"

const Wrapper = styled.div`
  border-radius: 20px;
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-gap: 30px;
`

const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 60px;
`

const AnimalsList = () => {
  const {
    selectedAnimal,
    animals,
    loadingAnimals: loading,
  } = useZookeeperSelectionState()

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <Loader color="#662d91" />
        </LoaderWrapper>
      ) : (
        <Wrapper>
          {(animals || []).slice(0, 6).map(z => (
            <Animal {...z} selected={selectedAnimal?._id === z._id} />
          ))}
        </Wrapper>
      )}
    </>
  )
}

AnimalsList.defaultProps = {
  nickname: null,
}

export default AnimalsList
