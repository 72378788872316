import { useApolloClient } from "@apollo/client"
import gql from "graphql-tag"
import useAsync from "../../../../../../../../../../hooks/useAsync"

export default () => {
  const client = useApolloClient()

  const { data: zookeepers, loading: loadingZookeepers } = useAsync({
    asyncFunc: async () => {
      const { data } = await client.query({
        query: gql`
          {
            me {
              _id
              children {
                _id
                nickname
                username
                yearGroup
                birthMonth
                birthYear
                countryCode
                avatar {
                  url
                }
              }
            }
          }
        `,
        fetchPolicy: "network-only",
      })
      return data.me.children
    },
    immediate: true,
    initialData: [],
  })

  const {
    execute: fetchAnimals,
    data: animals,
    loading: loadingAnimals,
  } = useAsync({
    asyncFunc: async params => {
      if (!params.username) return []
      const { data } = await client.query({
        query: gql`
          query getAnimals($username: String, $limit: Int, $desc: Boolean) {
            findUser(input: { username: $username }) {
              _id
              animals(skip: 0, limit: $limit, desc: $desc) {
                _id
                name
                createdAt
                artwork {
                  url
                }
              }
            }
          }
        `,
        variables: {
          username: params.username,
          limit: params.limit === 0 ? params.limit : params.limit || 6,
          desc: params.desc,
        },
        fetchPolicy: params.fetchPolicy || "cache-first",
      })
      return data.findUser.animals
    },
    immediate: false,
    initialData: [],
  })

  return {
    zookeepers,
    loadingZookeepers,
    animals,
    fetchAnimals,
    loadingAnimals,
  }
}
