import React from "react"
import styled from "styled-components"

interface IProps {
  title: string
  children: any
}

const Wrapper = styled.div`
  height: 100%;
  > :first-child {
    color: #662d91;
    font-size: 17px;
    font-family: "Libre Baskerville";
    margin-bottom: 12px;
    line-height: 21px;
  }
`
const Content = styled.div`
  position: relative;
  > :first-child {
    max-height: 400px;
    height: 100%;
    overflow: auto;
    border-radius: 20px;
    padding: 20px 37px 0 37px;
    background-color: #ebebeb;
    height: 400px;
  }
`

export default (props: IProps) => {
  return (
    <Wrapper>
      <div>{props.title}</div>
      <Content>{props.children}</Content>
    </Wrapper>
  )
}
