import { Button, Icon } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { useBookCreatorState } from "../../index.state"
import * as s from "../index.styles"

const BackButton = styled.div`
  position: absolute;
  top: 12px;
  left: 30px;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

interface IProps {
  children: any
  onBack: () => void
}

const ChooseAnimalLayout = (props: IProps) => {
  const { overrideStepTitle } = useBookCreatorState()

  return (
    <s.Step>
      <s.StepTitle padded>
        {overrideStepTitle || "Draw Your Hero Animal"}
        <BackButton onClick={props.onBack}>
          <Button round theme="primary" size="x-small">
            <Icon name="arrow-left" />
          </Button>
          Selection Method
        </BackButton>
      </s.StepTitle>
      <s.StepContent>{props.children}</s.StepContent>
    </s.Step>
  )
}

export default ChooseAnimalLayout
