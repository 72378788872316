import { Avatar } from "nzk-react-components"
import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { useZookeeperSelectionState } from "../index.state"

const Wrapper = styled.div`
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  ${(props: { selected: boolean }) =>
    props.selected
      ? css`
          background-color: #341644;
          box-shadow: 0 5px 0 #1c042b, 0 10px 0 rgba(0, 0, 0, 0.4);
          color: #fff;
        `
      : css`
          color: #662d91;
          background-color: #ebebeb;
          box-shadow: inset 0 5px 0 #fff, inset 0 -5px 0 #7a7a7a,
            0 5px 0 rgba(0, 0, 0, 0.4);
        `}

  margin-bottom: 10px;
  border-radius: 50px;
  font-family: "Rammetto One";
  > .user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      height: 17px;
      font-size: 14px;
    }
    padding: 12px 10px;
  }
`

export interface IZookeeper {
  _id: string
  username: string
  nickname?: string
  yearGroup: number
  birthMonth?: number
  birthYear?: number
  type: "student"
  countryCode: string
  avatar: {
    url: string
  }
}

interface IProps extends IZookeeper {
  selected: boolean
}

const Zookeeper = (props: IProps) => {
  const { selectZookeeper } = useZookeeperSelectionState()

  const age = useMemo(() => {
    const now = new Date()
    const yearNow = now.getFullYear()
    const monthNow = now.getMonth()

    if (props.birthYear && props.birthMonth) {
      let yearAge = yearNow - props.birthYear
      if (monthNow <= props.birthMonth) {
        yearAge -= 1
      }
      return yearAge
    }
    if (props.yearGroup) {
      return `${props.yearGroup + 5}`
    }
    return "Not Set"
  }, [])

  return (
    <Wrapper selected={props.selected} onClick={() => selectZookeeper(props)}>
      <Avatar user={props} size="76px" />
      <div className="user-info">
        <div className="user-info--username">{props.username}</div>
        <div className="user-info--nickname">{props.nickname || ""}</div>
        <div className="user-info--age">{age} years old</div>
      </div>
    </Wrapper>
  )
}

Zookeeper.defaultProps = {}

export default Zookeeper
