import React, { useEffect, useState } from "react"
// import { useBookCreatorState } from "../../index.state"
import styled from "styled-components"
import { Button } from "nzk-react-components"
import { graphql, useStaticQuery } from "gatsby"
import ChooseAnimalLayout from "../ChooseAnimalLayout"
import Animal from "./components/Animal"
import { useBookCreatorState } from "../../../index.state"

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const Animals = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 815px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export default props => {
  const { updateData } = useBookCreatorState()
  const [selectedAnimal, setSelectedAnimal] = useState<any>()

  const {
    allAnimalsJson: { edges },
  } = useStaticQuery(graphql`
    {
      allAnimalsJson {
        edges {
          node {
            name
            artworkUrl
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 550, quality: 70)
              }
            }
          }
        }
      }
    }
  `)

  const confirmAnimal = () => {
    updateData({
      animalImg: selectedAnimal.artworkUrl,
      animalName: selectedAnimal.name,
    })
  }

  useEffect(() => {
    if (selectedAnimal) {
      confirmAnimal()
    }
  }, [selectedAnimal])

  return (
    <ChooseAnimalLayout {...props}>
      <Wrapper>
        <Actions>
          {selectedAnimal && (
            <Button size="small" theme="primary" onClick={confirmAnimal}>
              Select animal
            </Button>
          )}
        </Actions>
        <Animals>
          {edges.map(({ node: animal }) => (
            <Animal
              key={animal.name}
              {...animal}
              selected={selectedAnimal && selectedAnimal.name === animal.name}
              selectAnimal={setSelectedAnimal}
            />
          ))}
        </Animals>
      </Wrapper>
    </ChooseAnimalLayout>
  )
}
