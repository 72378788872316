import React from "react"
import styled from "styled-components"
import { useZookeeperSelectionState } from "../index.state"
// @ts-ignore
import Cover from "./cover.png"

const Wrapper = styled.div`
  position: relative;
  > * {
    margin-bottom: 30px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  img {
    object-fit: contain;
    object-position: center;
    max-height: 345px;
  }
  img#cover {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  img#selected-animal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(10%);
    height: 25%;
    width: 25%;
  }
`

const Preview = () => {
  const { selectedAnimal } = useZookeeperSelectionState()

  return (
    <Wrapper>
      <img id="cover" src={Cover} alt="Cover" />
      {selectedAnimal && (
        <img
          id="selected-animal"
          src={selectedAnimal.artwork.url}
          alt="Selected Animal"
        />
      )}
    </Wrapper>
  )
}

export default Preview
