import React, { useEffect, useMemo } from "react"
import { useAuthenticationState } from "../../../../../../../../providers/AuthenticationProvider"
// import { useBookCreatorState } from "../../index.state"
import ChooseAnimalLayout from "../ChooseAnimalLayout"
import Login from "./components/Login"
import ZookeeperSelection from "./components/ZookeeperSelection"

export default props => {
  // const { onAnimalImage } = useBookCreatorState()
  const { isLoggedIn, currentUser, logout } = useAuthenticationState()

  useEffect(() => {
    if (currentUser && currentUser.type !== "parent") {
      logout()
    }
  }, [currentUser])

  const step = useMemo(() => {
    if (!isLoggedIn || (currentUser && currentUser.type !== "parent"))
      return <Login />
    if (isLoggedIn && currentUser && currentUser.type === "parent")
      return <ZookeeperSelection />
    return null
  }, [isLoggedIn])

  return <ChooseAnimalLayout {...props}>{step}</ChooseAnimalLayout>
}
