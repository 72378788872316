export const PREVIEWABLE = {
  ACT: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [212, 213],
    [222, 223],
    [227, 228],
  ],
  DRAW: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [212, 213],
    [222, 223],
    [227, 228],
  ],
  DANCE: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [208, 209],
    [210, 211],
    [216, 217],
    [222, 223],
    [227, 228],
  ],
  GAME: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [208, 209],
    [210, 211],
    [216, 217],
    [222, 223],
    [227, 228],
  ],
  SING: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [208, 209],
    [216, 217],
    [222, 223],
    [227, 228],
  ],
  WRITE: [
    "cover",
    [2, 3],
    [4, 5],
    [10, 11],
    [44, 45],
    [52, 53],
    [208, 209],
    [216, 217],
    [222, 223],
    [227, 228],
  ],
}

export default null
