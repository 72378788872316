import React, { useEffect, useState } from "react"
import { Steps, useBookCreatorState } from "../../index.state"
import Drawing from "./Drawing"
import Library from "./Library"
import NZK from "./NZK"
import SelectionType from "./SelectionType"

export type TAnimalType = {
  DRAWING: string
  UPLOAD: string
  NZK: string
  LIBRARY: string
}

export const AnimalType: TAnimalType = {
  DRAWING: "DRAWING",
  UPLOAD: "UPLOAD",
  NZK: "NZK",
  LIBRARY: "LIBRARY",
}

const ChooseAnimal = () => {
  const { data, setCurrentStep, animalSelectionType, setAnimalSelectionType } =
    useBookCreatorState()
  const [type, setType] = useState<keyof TAnimalType | null>(
    animalSelectionType
  )

  useEffect(() => {
    setCurrentStep(Steps.ANIMAL_NAME)
  }, [data.animalImg])

  useEffect(() => {
    setAnimalSelectionType(type)
  }, [type])

  useEffect(() => {}, [])

  if (!type) {
    return <SelectionType setType={setType} />
  }

  if (type === AnimalType.DRAWING) {
    return <Drawing onBack={() => setType(null)} />
  }

  if (type === AnimalType.UPLOAD) {
    return <Drawing openUploadOnStart onBack={() => setType(null)} />
  }

  if (type === AnimalType.LIBRARY) {
    return <Library onBack={() => setType(null)} />
  }

  if (type === AnimalType.NZK) {
    return <NZK onBack={() => setType(null)} />
  }

  return null
}

export default ChooseAnimal
