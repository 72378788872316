import { createContainer } from "lib/unstated"
import { useEffect, useState } from "react"
import { IAnimal } from "./AnimalsList/Animal"
import useData from "./useData"
import { IZookeeper } from "./ZookeepersList/Zookeeper"

const ZookeeperSelectionState = () => {
  const {
    zookeepers,
    loadingZookeepers,
    fetchAnimals,
    loadingAnimals,
    animals,
  } = useData()
  const [selectedZookeeper, selectZookeeper] = useState<IZookeeper | null>(null)
  const [selectedAnimal, selectAnimal] = useState<IAnimal | null>(null)
  const [selectingMore, setSelectingMore] = useState(false)
  const [filters, updateFilters] = useState({ desc: false })
  const [limit, setLimit] = useState(6)

  useEffect(() => {
    if (selectedZookeeper?.username) {
      fetchAnimals({ username: selectedZookeeper.username, desc: filters.desc })
    }
  }, [selectedZookeeper])

  useEffect(() => {
    if (zookeepers[0]) {
      selectZookeeper(zookeepers[0])
    }
  }, [zookeepers])

  useEffect(() => {
    if (selectedZookeeper?.username) {
      fetchAnimals({
        username: selectedZookeeper.username,
        limit,
        desc: filters.desc,
      })
    }
  }, [filters, limit])

  return {
    zookeepers,
    fetchAnimals: forcedLimit => {
      if (selectedZookeeper?.username) {
        fetchAnimals({
          username: selectedZookeeper.username,
          limit: forcedLimit || limit,
          desc: filters.desc,
        })
      }
    },
    loadingZookeepers,
    selectZookeeper,
    selectedZookeeper,
    animals,
    filters,
    limit,
    setLimit,
    updateFilters,
    selectAnimal,
    selectedAnimal,
    loadingAnimals,
    selectingMore,
    setSelectingMore,
  }
}

const ZookeeperSelectionStateContainer = createContainer(
  ZookeeperSelectionState
)

export const ZookeeperSelectionStateProvider =
  ZookeeperSelectionStateContainer.Provider

export const useZookeeperSelectionState =
  ZookeeperSelectionStateContainer.useContainer
