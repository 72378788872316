import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "@reach/router"
import { useShopLocaleState } from "../../../../../providers/ShopLocaleProvider"
import { createContainer } from "../../../../../lib/unstated"
import { TAnimalType } from "./steps/ChooseAnimal"
import ChangeSelectionModal from "./steps/ChooseAnimal/ChangeSelectionModal"
import useModal from "../../../../../hooks/useModal"

export const Steps = {
  CHOOSE_ANIMAL: "CHOOSE_ANIMAL",
  ANIMAL_NAME: "ANIMAL_NAME",
  FAVOURITE_SKILL: "FAVOURITE_SKILL",
  ADD_DEDICATION: "ADD_DEDICATION",
  PREVIEW: "PREVIEW",
}

export const steps = [
  { id: Steps.CHOOSE_ANIMAL, title: "Choose Animal" },
  { id: Steps.ANIMAL_NAME, title: "Animal Details" },
  { id: Steps.ADD_DEDICATION, title: "Dedication" },
  { id: Steps.PREVIEW, title: "Confirmation" },
]

interface BookCreatorData {
  favouriteSkill?: string
  animalName?: string
  zookeeperName?: string
  animalImg?: string
  customMessage?: string
  drawingToolCache?: string
}

const BookCreatorState = () => {
  const [data, setData] = useState<BookCreatorData>({})
  const [animalSelectionType, setAnimalSelectionType] = useState<
    keyof TAnimalType | null
  >(null)
  const [overrideStepTitle, setOverrideStepTitle] = useState<string | null>(
    null
  )
  const [editing, setEditing] = useState<string | null>(null)
  const [currentStep, _setCurrentStep] = useState(Steps.CHOOSE_ANIMAL)

  const { locale } = useShopLocaleState()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("id")) {
      setEditing(params.get("id"))
    }
    setData({
      animalImg: params.get("animalImg") || undefined,
      animalName: params.get("animalName") || undefined,
      zookeeperName: params.get("username") || undefined,
      customMessage: params.get("customMessage") || undefined,
      favouriteSkill: params.get("favouriteSkill") || undefined,
      drawingToolCache: params.get("drawingToolCache") || undefined,
    })
  }, [])

  const { strapiShopProducts } = useStaticQuery(graphql`
    {
      strapiShopProducts(
        slug: { eq: "personalized-escape-from-conform-prison" }
      ) {
        id
        slug
        name
        thumbnail {
          url
        }
        digital
        skus {
          id
          sku
          title
          warehouse
          thumbnail {
            url
          }
          pricings {
            slug
            shippingRate
            shopShippingRate {
              id
              country {
                id
                locale
              }
            }
            countries {
              currencyCode
              code
              locale
              conversionRateToUSD
            }
            currency
            amount
          }
        }
      }
    }
  `)

  const countries = strapiShopProducts.skus.reduce((acc, sku) => {
    const countries = sku.pricings.reduce(
      (acc: any[], p) => [...acc, ...p.countries],
      []
    )
    return [...acc, ...countries]
  }, [])

  const country = useMemo(() => {
    return countries.find(c => c.locale === locale)
  }, [locale, countries])

  const updateData = (payload: BookCreatorData) =>
    setData(d => ({ ...d, ...payload }))

  const setCurrentStep = step => {
    let newStep = step
    if (newStep === Steps.ADD_DEDICATION && !data.animalName)
      newStep = Steps.ANIMAL_NAME
    if (newStep === Steps.ANIMAL_NAME && !data.animalImg)
      newStep = Steps.CHOOSE_ANIMAL
    _setCurrentStep(newStep)
  }

  const onAnimalImage = (animalImg: string) => updateData({ animalImg })
  const onAnimalName = (animalName: string) => updateData({ animalName })
  const onCustomMessage = (customMessage: string) =>
    updateData({ customMessage })

  const buyOptions = useMemo(() => {
    return strapiShopProducts.skus.reduce((acc, sku) => {
      const relevantPricings = sku.pricings.filter(
        pricing => pricing.countries.map(c => c.locale).indexOf(locale) >= 0
      )
      if (relevantPricings.length > 0) {
        return [...acc, { ...sku, pricing: relevantPricings[0] }]
      }
      return acc
    }, [])
  }, [strapiShopProducts, locale])

  const {
    Modal: ChangeAnimalModal,
    open: openChangeAnimalModal,
    dismiss: dismissChangeAnimalModal,
  } = useModal(
    <ChangeSelectionModal
      onSelected={d => {
        setAnimalSelectionType(d)
        updateData({ animalImg: undefined })
        setCurrentStep(Steps.CHOOSE_ANIMAL)
        dismissChangeAnimalModal()
      }}
    />
  )

  return {
    data,
    countries,
    currency: country?.currency,
    buyOptions,
    currentStep,
    setCurrentStep,
    animalSelectionType,
    setAnimalSelectionType,
    onAnimalImage,
    product: strapiShopProducts,
    editing,
    updateData,
    onCustomMessage,
    onAnimalName,
    overrideStepTitle,
    setOverrideStepTitle,
    ChangeAnimalModal,
    openChangeAnimalModal,
    dismissChangeAnimalModal,
    available: (countries || []).filter(c => c.locale === locale).length > 0,
  }
}

const BookCreatorStateContainer = createContainer(BookCreatorState)
export const BookCreatorStateProvider = BookCreatorStateContainer.Provider
export const useBookCreatorState = BookCreatorStateContainer.useContainer
