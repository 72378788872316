import React from "react"
import styled from "styled-components"
import { useZookeeperSelectionState } from "../index.state"
import Zookeeper from "./Zookeeper"

const Wrapper = styled.div`
  > * {
    margin-bottom: 30px;
  }
`

const ZookeepersList = () => {
  const { selectedZookeeper, zookeepers } = useZookeeperSelectionState()

  return (
    <Wrapper>
      {zookeepers.map(z => (
        <Zookeeper {...z} selected={selectedZookeeper?._id === z._id} />
      ))}
    </Wrapper>
  )
}

ZookeepersList.defaultProps = {
  nickname: null,
}

export default ZookeepersList
