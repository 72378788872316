import { animated, useSpring } from "react-spring"
import React, { useEffect } from "react"
import styled from "styled-components"
import { Document, Page } from "react-pdf"
import { useDrag } from "react-use-gesture"
import Loader from "components/UI/Loader"
import BottomBook from "./BottomBook"

const Spread = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  > :first-child {
    transform-origin: 100% 0;
  }
  > :nth-child(2) {
    transform-origin: 0% 0;
  }
  > :nth-child(1) {
    position: relative;
    box-shadow: 5px 0px 5px -4px rgba(0, 0, 0, 0.3) inset;
    :after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(255, 255, 255, 0);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 74%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }
  canvas {
    height: 100% !important;
    width: 113% !important;
  }
`

const PageWrapper = styled(animated.div)`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-style: preserve-3d;
  > :first-child {
    z-index: -1;
    position: absolute;
    left: -9%;
    top: -6%;
  }
  user-select: none;
  cursor: pointer;
`

const LoadingWrapper = styled(Spread)`
  width: 100%;
  background-color: #ebebeb;

  .title {
    margin-left: 20px;
    margin-top: 30px;
    font-size: 21px;
    font-weight: bold;
    color: #662d91;
  }
  > * > :first-child {
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
`

const PDFLoadingWrapper = styled(LoadingWrapper)`
  position: absolute;
  height: 100%;
`

const CoverWrapper = styled(PageWrapper)`
  > :first-child {
    left: -2%;
    top: -3%;
  }
  :before,
  :after {
    position: absolute;
    z-index: 10;
    content: "";
    top: 0;
    height: 100%;
    width: 100%;
  }
  :before {
    left: 53%;
    width: 47%;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 74%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
  :after {
    right: 53%;
    width: 47%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 74%,
      rgba(0, 0, 0, 0.75) 100%
    );
  }
  canvas {
    height: 95% !important;
    width: 104% !important;
  }
`

const DocumentWrapper = styled.div`
  > :first-child {
    position: relative;
    display: flex;
    width: 100%;
    height: var(--height);
    > :last-child {
      z-index: -1;
    }
  }
`

const StyledDocument = props => {
  if (typeof window === "undefined") return null
  return (
    <DocumentWrapper>
      <Document {...props} />
    </DocumentWrapper>
  )
}

interface IProps {
  data: any
  cover: boolean
  canNext: boolean
  updateSwiping: (v: boolean) => void
  canPrevious: boolean
  gestures: boolean
  spread: string
  setDirection: (str: "next" | "previous") => void
  onNext: () => void
  onPrevious: () => void
}

export default (props: IProps) => {
  const { data, cover, canNext, canPrevious, onNext, onPrevious } = props
  const [rightPageSpring, setRightPageSpring] = useSpring(() => ({
    transform: `rotateX(0) rotateY(0) scale(1)`,
  }))

  const [leftPageSpring, setLeftPageSpring] = useSpring(() => ({
    transform: `rotateX(0) rotateY(0) scale(1)`,
  }))

  useEffect(() => {
    setRightPageSpring({
      transform: `rotateX(0deg) rotateY(0deg) scale(1)`,
    })
    setLeftPageSpring({
      transform: `rotateX(0deg) rotateY(0deg) scale(1)`,
    })
  }, [])

  const animateRightToFrame = (progress = 1) => {
    if (progress === 0) {
      setRightPageSpring({
        transform: `rotateX(0deg) rotateY(0deg) scale(1)`,
      })
    } else {
      setRightPageSpring({
        transform: `rotateX(10deg) rotateY(${progress * 180}deg) scale(1.015)`,
      })
    }
  }

  const animateLeftToFrame = (progress = 1) => {
    if (progress === 0) {
      setLeftPageSpring({
        transform: `rotateX(0deg) rotateY(0deg) scale(1)`,
      })
    } else {
      setLeftPageSpring({
        transform: `rotateX(10deg) rotateY(${-progress * 180}deg) scale(1.015)`,
      })
    }
  }

  const bind = useDrag(state => {
    const {
      active,
      event,
      movement: [mx],
      direction: [xDir],
      distance,
      cancel,
    } = state
    props.updateSwiping(active)
    if ((active && xDir > 0 && !canPrevious) || (xDir < 0 && !canNext)) {
      animateRightToFrame(0)
      animateLeftToFrame(0)
      cancel()
    }
    // @ts-ignore
    const { target }: { target: HTMLDivElement | null } = event
    const WIDTH = (target?.clientWidth || 400) / (cover ? 2 : 1)
    if (active && distance > WIDTH) {
      cancel()
      if (xDir > 0) onPrevious()
      else onNext()
    }
    if (active && mx < 0) {
      props.setDirection("next")
      animateRightToFrame(Math.min(distance / (2 * WIDTH), 1))
      animateLeftToFrame(0)
    } else if (active && mx > 0) {
      props.setDirection("previous")
      animateLeftToFrame(Math.min(distance / (2 * WIDTH), 1))
      animateRightToFrame(0)
    } else {
      animateRightToFrame(0)
      animateLeftToFrame(0)
    }
  })

  return (
    <StyledDocument
      file={data}
      key={props.spread}
      {...(props.gestures ? bind() : {})}
      loading={
        <PDFLoadingWrapper>
          <PageWrapper>
            <Loader size={50} color="#662d91" bgColor="transparent" />
          </PageWrapper>
          <PageWrapper>
            <Loader size={50} color="#662d91" bgColor="transparent" />
          </PageWrapper>
          <BottomBook />
        </PDFLoadingWrapper>
      }
    >
      {cover ? (
        <CoverWrapper>
          <Page pageNumber={1} />
        </CoverWrapper>
      ) : (
        <Spread>
          <PageWrapper key={`${props.spread}-left`} style={leftPageSpring}>
            <Page pageNumber={1} />
          </PageWrapper>
          <PageWrapper key={`${props.spread}-right`} style={rightPageSpring}>
            <Page pageNumber={2} />
          </PageWrapper>
        </Spread>
      )}

      <BottomBook />
    </StyledDocument>
  )
}
