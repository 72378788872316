import {
  Steps,
  useBookCreatorState,
} from "components/BookCreatorProduct/EscapeFromConformPrison/components/BookCreator/index.state"
import Select from "components/BookCreatorProduct/EscapeFromConformPrison/components/UI/Select"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { useAuthenticationState } from "../../../../../../../../../../providers/AuthenticationProvider"
import AnimalsList from "./AnimalsList"
import {
  useZookeeperSelectionState,
  ZookeeperSelectionStateProvider,
} from "./index.state"
import MoreAnimals from "./MoreAnimals"
import Preview from "./Preview"
import Section from "./Section"
import ZookeepersList from "./ZookeepersList"

const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  .select-animal-button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
    width: 100%;
    margin-right: 30px;
  }
  > :last-child {
    margin-right: 0;
  }
  > * {
    min-width: 400px;
    max-width: 100%;
    width: calc(33% - 30px);
  }
  @media (max-width: 1305px) {
    > :first-child {
      width: 100%;
      margin-right: 0;
    }
    > :nth-child(2) {
      width: calc(50% - 30px);
    }
    > :nth-child(3) {
      width: 50%;
    }
  }
  @media (max-width: 910px) {
    > :first-child {
      min-width: initial;
    }
    > :nth-child(2) {
      min-width: initial;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
    > :nth-child(3) {
      min-width: initial;
      width: 100%;
    }
  }
`

const SortSelect = styled.div`
  position: absolute;
  top: -44px;
  right: 9px;
  width: 118px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
`

const ZookeeperSelection = () => {
  const { logout } = useAuthenticationState()
  const {
    selectedZookeeper,
    selectedAnimal,
    selectingMore,
    setSelectingMore,
    updateFilters,
    filters,
  } = useZookeeperSelectionState()

  const { updateData, setCurrentStep } = useBookCreatorState()

  const onAnimalConfirmed = () => {
    updateData({
      animalImg: selectedAnimal?.artwork.url,
      animalName: selectedAnimal?.name,
      zookeeperName: selectedZookeeper?.username,
    })
    setCurrentStep(Steps.ANIMAL_NAME)
  }

  if (selectingMore) return <MoreAnimals confirmAnimal={onAnimalConfirmed} />
  return (
    <Container>
      {selectedAnimal && (
        <Actions>
          <Button
            size="regular"
            theme="confirm"
            wiggle
            onClick={onAnimalConfirmed}
          >
            Select Animal
          </Button>
        </Actions>
      )}
      <Wrapper>
        <Section title="Select a Zookeeper">
          <ZookeepersList />
          <Actions>
            <Button size="x-small" theme="primary" onClick={logout}>
              Logout
            </Button>
          </Actions>
        </Section>
        <Section title="Sample page preview">
          <Preview />
          {selectedAnimal && (
            <Actions>
              <Button
                size="regular"
                theme="confirm"
                wiggle
                onClick={onAnimalConfirmed}
              >
                Select Animal
              </Button>
            </Actions>
          )}
        </Section>
        <Section title="Select an Animal">
          <AnimalsList />
          <SortSelect>
            <Select
              onChange={e =>
                updateFilters({
                  desc: e === "DESC",
                })
              }
              theme={{
                color: "#662d91",
                selectedColor: "#ebebeb",
                focusedColor: "#ffffff",
                borderColor: "transparent",
                fontSize: "14px",
              }}
              value={filters.desc ? "DESC" : "ASC"}
              options={[
                { label: "Oldest", value: "ASC" },
                { label: "Newest", value: "DESC" },
              ]}
            />
          </SortSelect>
          <Actions>
            <Button
              size="small"
              theme="primary"
              onClick={() => setSelectingMore(true)}
            >
              See more
            </Button>
          </Actions>
        </Section>
      </Wrapper>
    </Container>
  )
}

export default props => {
  return (
    <ZookeeperSelectionStateProvider>
      <ZookeeperSelection {...props} />
    </ZookeeperSelectionStateProvider>
  )
}
