import Input from "components/BookCreatorProduct/EscapeFromConformPrison/components/UI/Input"
import Select from "components/BookCreatorProduct/EscapeFromConformPrison/components/UI/Select"
import { Button, Icon } from "nzk-react-components"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"
import { Steps, useBookCreatorState } from "../../index.state"
import * as s from "../index.styles"
// @ts-ignore
import Spread from "../ChooseAnimal/images/spread.png"

const Header = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 25px;
  color: #662d91;
`

const BackButton = styled.div`
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

const Content = styled.div`
  color: #662d91;
  display: flex;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
`

const Section = styled.section`
  .title {
    margin-bottom: 12px;
  }
`

const PagePreview = styled.div`
  background-color: #ebebeb;
  border-radius: 15px;
  .thumbnail {
    position: relative;
    width: 100%;
    > :first-child {
      width: 100%;
      height: 100%;
    }
    > :last-child {
      position: absolute;
      top: 17%;
      right: 13%;
      height: 45%;
      width: 33%;
      object-fit: contain;
    }
  }
`

const Form = styled.div`
  background-color: #ebebeb;
  border-radius: 15px;
  padding: 10px 15px;
`

const PagePreviewSection = styled(Section)`
  width: 55%;
  margin-right: 30px;
  min-width: 456px;
  margin-bottom: 30px;

  @media (max-width: 868px) {
    min-width: 335px;
    width: 100%;
    margin-right: 0;
  }
`

const FormSection = styled(Section)`
  width: calc(44% - 30px);
  @media (max-width: 868px) {
    width: 100%;
  }
`

const Field = styled.div`
  .field-title {
    font-family: "Rammetto One";
  }
  .field-error {
    color: #f00;
    font-size: 12px;
    margin-bottom: 6px;
  }
  margin-bottom: 10px;
`

const InformationBanner = styled.div`
  background-color: #662d91;
  border-radius: 12px;
  color: #fff;
  padding: 13px 22px;
  font-size: 14px;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const validateName = name => {
  const acc = (name || "").split(" ").reduce((acc, word) => {
    const newAcc = [...acc]
    if (!newAcc[newAcc.length - 1]) {
      newAcc.push("")
    }
    const fit = (newAcc[newAcc.length - 1].length || 0) + word.length + 1 < 14
    if (fit) {
      newAcc[newAcc.length - 1] = `${
        newAcc[newAcc.length - 1] ? `${newAcc[newAcc.length - 1]} ` : ""
      }${word}`
    } else {
      newAcc.push(word)
    }
    return newAcc
  }, [])
  if (acc.length > 2) return false
  if (acc.find(c => c.length > 14)) return false
  return true
}

const AnimalDetails = () => {
  const { data, setCurrentStep, updateData, openChangeAnimalModal } =
    useBookCreatorState()

  useEffect(() => {
    if (!data.animalImg) setCurrentStep(Steps.CHOOSE_ANIMAL)
  }, [])

  const animalError = useMemo(() => {
    return !validateName(data.animalName)
  }, [data])

  const authorNameError = useMemo(() => {
    return (data.zookeeperName || "").length > 25
  }, [data])

  const canSubmit = useMemo(() => {
    return (
      !animalError &&
      !authorNameError &&
      data.animalName &&
      data.favouriteSkill &&
      data.zookeeperName
    )
  }, [data, animalError, authorNameError])

  const onSave = () => {
    if (canSubmit) {
      setCurrentStep(Steps.ADD_DEDICATION)
    }
  }

  const onChangeAnimal = () => {
    openChangeAnimalModal()
  }

  return (
    <s.Step>
      <s.StepContent>
        <Header>
          <BackButton
            onClick={() => {
              updateData({ animalImg: undefined })
              setCurrentStep(Steps.CHOOSE_ANIMAL)
            }}
          >
            <Button round theme="primary" size="x-small">
              <Icon name="arrow-left" />
            </Button>
            Select Animal
          </BackButton>
        </Header>

        <Content>
          <PagePreviewSection>
            <div className="title">Page Preview</div>
            <PagePreview>
              <div className="thumbnail">
                <img src={Spread} alt="Spread" />
                <img src={data.animalImg} alt="Animal" />
              </div>
            </PagePreview>
          </PagePreviewSection>
          <FormSection>
            <div className="title">Details</div>
            <Form>
              <Field>
                <div className="field-title">Name</div>
                {animalError && (
                  <div className="field-error">This name is too long</div>
                )}
                <Input
                  placeholder="Animal Name"
                  value={data.animalName || ""}
                  onChange={e => updateData({ animalName: e.target.value })}
                  error={animalError}
                />
              </Field>
              <Field>
                <div className="field-title">Animal Skill</div>
                <Select
                  borderRadius="50px"
                  noShadow
                  placeholder="Select a skill"
                  theme={{
                    color: "#662d91",
                    selectedColor: "#fff",
                    focusedColor: "#fff",
                    borderColor: "transparent",
                    fontSize: "14px",
                  }}
                  options={[
                    { value: "WRITE", label: "Write" },
                    { value: "SING", label: "Sing" },
                    { value: "ACT", label: "Act" },
                    { value: "DRAW", label: "Draw" },
                    { value: "GAME", label: "Game" },
                    { value: "DANCE", label: "Dance" },
                  ]}
                  value={data.favouriteSkill || ""}
                  onChange={value => updateData({ favouriteSkill: value })}
                />
              </Field>
              <InformationBanner>
                This skill helps the animal save the Night Zoo.
              </InformationBanner>
              <Field>
                <div className="field-title">Created by</div>
                {authorNameError && (
                  <div className="field-error">Author name is too long</div>
                )}
                <Input
                  placeholder="Name"
                  value={data.zookeeperName || ""}
                  onChange={e => {
                    updateData({ zookeeperName: e.target.value })
                  }}
                  error={authorNameError}
                />
              </Field>
              <Action>
                <Button
                  theme="confirm"
                  size="small"
                  disabled={!canSubmit}
                  onClick={onSave}
                >
                  Next
                </Button>
              </Action>
            </Form>
            <Action>
              <Button theme="orange" size="x-small" onClick={onChangeAnimal}>
                Change Animal
              </Button>
            </Action>
          </FormSection>
        </Content>
      </s.StepContent>
    </s.Step>
  )
}

export default AnimalDetails
