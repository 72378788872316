import styled, { css } from "styled-components"

interface IInputProps {
  error?: boolean
}

export default styled.input`
  width: 100%;
  border-radius: 50px;
  border: none;
  padding: 6px 12px;
  font-family: "Libre Baskerville";
  outline: none;
  ${(props: IInputProps) => {
    if (props.error)
      return css`
        border: 1px solid #f00;
        animation: input-error infinite 0.2s;
        animation-iteration-count: 3;
      `
  }}
  @keyframes input-error {
    0% {
      transform: rotate(2deg);
    }
    66% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`
