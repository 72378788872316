import { useState } from "react"
import debounce from "utils/debounce"
import useEventListener from "./useEventListener"

const HAS_WINDOW = typeof window !== "undefined"
const useScroll = () => {
  const [scroll, setScroll] = useState({
    top: HAS_WINDOW ? window.scrollX : 0,
    left: HAS_WINDOW ? window.scrollY : 0,
  })

  const scrollToElement = selectors => {
    if (typeof window === "undefined") return
    let target: HTMLElement | null = null
    if (typeof selectors === "string") {
      target = document.querySelector(selectors)
    } else {
      target = selectors
    }
    if (!target) return
    window.scrollTo({
      top: target.offsetTop - target.offsetHeight / 2,
      behavior: "smooth",
    })
  }

  const scrollTop = top => {
    if (typeof window === "undefined") return
    window.scrollTo({
      top,
      behavior: "smooth",
    })
  }

  useEventListener(
    "scroll",
    debounce(() => {
      if (typeof window === 'undefined') return

      setScroll({ top: window.scrollY, left: window.scrollX })
    }, 250),
    []
  )

  return {
    scrollToElement,
    scrollTop,
    scroll,
  }
}

export default useScroll
