import Loader from "components/UI/Loader"
import React from "react"
import styled from "styled-components"
import BottomBook from "./BottomBook"

const Wrapper = styled.div``

const Spread = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  background-color: #bfb4cf;
  > .page {
    width: 100%;
    flex: 1;
  }
  > :nth-child(1) {
    position: relative;
    box-shadow: 5px 0px 5px -4px rgba(0, 0, 0, 0.3) inset;
    :after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(255, 255, 255, 0);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 74%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }
  > .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default () => {
  return (
    <Wrapper>
      <Spread>
        <div className="page" id="page-left" />
        <div className="page" id="page-right" />
        <div className="loader">
          <Loader color="#5F3491" size={110} />
        </div>
        <BottomBook />
      </Spread>
    </Wrapper>
  )
}
