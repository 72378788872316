import React from "react"
import styled from "styled-components"
import ShopHeader from "components/ShopHeader"
import ShopLayout from "../../../../../layouts/ShopLayout"
import { EscapeFromConformPrison } from "../../../../../components/BookCreatorProduct"

const Wrapper = styled.div`
  > :first-child {
    padding-top: 50px;
  }
`

export default () => {
  return (
    <>
      <ShopLayout>
        <Wrapper>
          <ShopHeader />
          <EscapeFromConformPrison />
        </Wrapper>
      </ShopLayout>
    </>
  )
}
