import { Button, Icon } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { AnimalType } from "."

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  overflow: auto;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 15px;
  max-width: 320px;
  box-shadow: 0 5px 0 #afafaf, 0 10px 0 rgba(0, 0, 0, 0.5);
  color: #6f1ea9;
  text-align: center;

  .thumbnail {
    height: 170px;
    width: 240px;
    background-color: #ebebeb;
  }
  font-size: 13px;
  .title {
    font-size: 33px;
    margin-bottom: 20px;
  }
`

const QuitButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`

const Content = styled.div`
  position: relative;
  padding: 0px 100px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .thumbnail {
    border-radius: 20px;
    overflow: hidden;
    background-color: #662d91;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  > * {
    margin-bottom: 15px;
  }
  margin-bottom: 5px;
`

export default (props: {
  dismiss: () => void
  setType: (type: any) => void
}) => {
  return (
    <Overlay onClick={props.dismiss}>
      <Content>
        <Wrapper
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <QuitButton onClick={props.dismiss}>
            <Button size="small" round theme="red">
              <Icon name="close" />
            </Button>
          </QuitButton>
          <div className="title">Create your animal</div>
          <Element>
            <Button
              theme="primary"
              size="regular"
              onClick={() => props.setType(AnimalType.DRAWING)}
            >
              Draw online
            </Button>
            <p>
              Get your child to create the animal with our online drawing tool.
            </p>
          </Element>
          <Element>
            <Button
              theme="primary"
              size="regular"
              onClick={() => props.setType(AnimalType.UPLOAD)}
            >
              Upload
            </Button>
            <p>Use your camera to upload a hand-drawn picture.</p>
          </Element>
        </Wrapper>
      </Content>
    </Overlay>
  )
}
