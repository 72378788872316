import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

const Wrapper = styled.div`
  min-height: 500px;
  color: #662d91;
  padding-top: 20px;
  padding-bottom: 50px;
`

const Title = styled.div`
  text-align: center;
  font-family: "Rammetto One";
  font-size: 24px;
`

const Content = styled.div`
  text-align: center;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :last-child {
    margin-top: 20px;
  }
`

const Product = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .product--image {
    width: 350px;
    background-color: rgb(235, 235, 235);
    padding: 30px;
    border-radius: 20px;
  }
  .product--name {
    font-weight: bold;
    font-size: 18px;
    margin-top: 12px;
  }
`

interface IProps {
  product?: Strapi.ShopProduct
}

const ProductUnavailable = (props: IProps) => (
  <Wrapper>
    <Title>Product unavailable</Title>
    <Content>
      This product is not available for the selected country.
      {props.product && (
        <Product>
          {props.product.thumbnail && (
            <div className="product--image">
              <GatsbyImage
                image={getGatsbyImage(props.product.thumbnail.localFile)}
                alt={props.product.thumbnail.alternativeText || ""}
              />
            </div>
          )}
          <div className="product--name">{props.product.name}</div>
        </Product>
      )}
      <Button size="regular" theme="purple" onClick={() => navigate(-1)}>
        Back
      </Button>
    </Content>
  </Wrapper>
)

ProductUnavailable.defaultProps = {
  product: null,
}

export default ProductUnavailable
