import React, { useEffect } from "react"
import styled from "styled-components"
import { Button, IconButton, Icon } from "nzk-react-components"
import Loader from "components/UI/Loader"
import Select from "components/BookCreatorProduct/EscapeFromConformPrison/components/UI/Select"
import { useZookeeperSelectionState } from "../index.state"
import Animal from "../AnimalsList/Animal"

const Wrapper = styled.div`
  max-width: 1200px;
`

const Animals = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 815px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 540px) {
    grid-template-columns: 1fr 1fr;
  }
`
const LoaderWrapper = styled.div`
  margin-top: 100px;
  padding-bottom: 100px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`

const SortSelect = styled.div`
  width: 120px;
`

export default (props: { confirmAnimal: () => void }) => {
  const {
    animals,
    loadingAnimals,
    selectedAnimal,
    setSelectingMore,
    updateFilters,
    filters,
    setLimit,
  } = useZookeeperSelectionState()

  useEffect(() => {
    props.confirmAnimal()
  }, [selectedAnimal])

  useEffect(() => {
    setLimit(0)
  }, [])

  const onSelectAnimalAction = () => {
    setSelectingMore(false)
  }

  const onBack = () => {
    setSelectingMore(false)
  }

  if (loadingAnimals) {
    return (
      <LoaderWrapper>
        <Loader color="#662d91" />
      </LoaderWrapper>
    )
  }

  return (
    <Wrapper>
      <Actions>
        <IconButton
          size="small"
          theme="primary"
          onClick={onBack}
          icon={<Icon name="arrow-left" />}
        >
          Back
        </IconButton>
        {selectedAnimal && (
          <Button size="small" theme="primary" onClick={onSelectAnimalAction}>
            Select animal
          </Button>
        )}
        <SortSelect>
          <Select
            onChange={e =>
              updateFilters({
                desc: e === "DESC",
              })
            }
            theme={{
              color: "#662d91",
              selectedColor: "#ebebeb",
              focusedColor: "#ffffff",
              borderColor: "transparent",
              fontSize: "14px",
            }}
            value={filters.desc ? "DESC" : "ASC"}
            options={[
              { label: "Oldest", value: "ASC" },
              { label: "Newest", value: "DESC" },
            ]}
          />
        </SortSelect>
      </Actions>
      <Animals>
        {animals.map(animal => (
          <Animal {...animal} selected={selectedAnimal?._id === animal._id} />
        ))}
      </Animals>
    </Wrapper>
  )
}
