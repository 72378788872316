import { Button } from "nzk-react-components"
import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from 'gatsby'
import { AnimalType } from "."
import * as s from "../index.styles"
import DrawUploadModal from "./DrawUploadModal"
// @ts-ignore
import Spread from "./images/spread.png"
// @ts-ignore
import AnimalShape from "./images/shape.png"

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  flex-wrap: wrap;
`

const Section = styled.section`
  .title {
    color: #662d91;
    margin-bottom: 10px;
  }
  .content {
    background-color: #ebebeb;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
  }
  .other-options {
    margin-top: 35px;
  }
`

const CoverPreviewSection = styled(Section)`
  width: calc(55% - 25px);
  margin-right: 25px;
  min-width: 450px;
  @media (max-width: 849px) {
    width: 100%;
    margin-right: 0;
    max-width: 450px;
    min-width: 335px;
    margin: 0 auto;
  }
`

const BookPreviewImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img#spread {
    height: 100%;
    width: 100%;
  }
  img#shape {
    position: absolute;
    top: 14%;
    right: 12%;
    height: 46%;
  }
`

const OptionsSection = styled(Section)`
  width: 44%;
  @media (max-width: 849px) {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 25px;
  }
`

const Options = styled.div`
  color: #662d91;
  display: flex;
  flex-direction: column;
  align-items: center;

  > :last-child {
    margin-bottom: 0;
  }
`

const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  > * {
    margin-bottom: 15px !important;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .option-link {
    text-decoration: underline;
    cursor: pointer;
  }
`

const SelectionType = ({ setType }: { setType: (type: any) => void }) => {
  const [drawUploadDisplayed, setDrawUploadDisplayed] = useState(false)

  const drawUploadSelected = () => {
    setDrawUploadDisplayed(true)
  }

  return (
    <>
      {drawUploadDisplayed && (
        <DrawUploadModal
          dismiss={() => setDrawUploadDisplayed(false)}
          setType={setType}
        />
      )}
      <s.Step>
        <s.StepTitle>Draw Your Hero Animal</s.StepTitle>
        <s.StepInfo>
          This book includes one character chosen by you! It's time to decide
          what this character looks like.
        </s.StepInfo>
        <s.StepContent>
          <Content>
            <CoverPreviewSection>
              <div className="title">Sample page preview</div>
              <div className="content">
                <BookPreviewImage>
                  <img id="spread" src={Spread} alt="Spread" />
                  <img id="shape" src={AnimalShape} alt="Shape" />
                </BookPreviewImage>
              </div>
            </CoverPreviewSection>
            <OptionsSection>
              <div className="title">Animal Options</div>
              <div className="content">
                <Options>
                  <OptionGroup>
                    <Button
                      theme="primary"
                      size="regular"
                      onClick={() => drawUploadSelected()}
                    >
                      Draw now
                    </Button>
                    <Button
                      theme="primary"
                      size="regular"
                      onClick={() => setType(AnimalType.LIBRARY)}
                    >
                      Ideas
                    </Button>
                  </OptionGroup>
                  <OptionGroup>
                    <div className="option-title">Are you a Night Zookeeper user?</div>
                    <div className='option-link' onClick={() => setType(AnimalType.NZK)} role='button' tabIndex={0} onKeyPress={(e) => e.which === 13 && setType(AnimalType.NZK)}>
                      Select one of your kids creations.
                    </div>
                  </OptionGroup>
                </Options>
              </div>
              <div className='other-options'>
                <OptionGroup>
                  <Button theme='orange' size='small' onClick={() => navigate('/shop/products/personalized-book-gift-voucher')}>
                    Personalize later
                  </Button>
                </OptionGroup>
              </div>
            </OptionsSection>
          </Content>
        </s.StepContent>
      </s.Step>
    </>
  )
}

export default SelectionType
