import React from "react"
import ReactSelect from "react-select"

interface IProps {
  options: { label: string; value: string | number }[]
  value: string
  onChange: (string) => void
  placeholder?: string
  borderRadius?: string
  noShadow?: boolean
  theme?: {
    color: string
    selectedColor: string
    focusedColor: string
    borderColor: string
    fontSize?: string
  }
}

const Select = (props: IProps) => {
  const onChange = option => {
    props.onChange(option.value)
  }

  const theme = props.theme || {
    color: "#662d91",
    selectedColor: "#EC5E2E",
    focusedColor: "rgb(248, 178, 156, 0.5)",
    borderColor: "rgb(248, 178, 156)",
  }

  const borderRadius = props.borderRadius || "8px"

  const customStyles = {
    option: (provided, state) => {
      let backgroundColor = "#fff"
      if (state.isSelected) backgroundColor = theme.selectedColor
      if (state.isFocused) backgroundColor = theme.focusedColor
      return {
        ...provided,
        color: theme.color,
        backgroundColor,
        fontSize: theme.fontSize,
        padding: 10,
      }
    },
    control: () => ({
      borderColor: "#f00",
      color: theme.color,
      display: "flex",
      borderRadius,
      backgroundColor: "white",
      boxShadow: props.noShadow ? "" : "rgb(0 0 0 / 10%) 0px 3px 2px",
      width: "100%",
      fontSize: theme.fontSize,
      outline: "none",
    }),
  }

  return (
    <ReactSelect
      onChange={l => onChange(l)}
      value={props.options.filter(o => o.value === props.value)}
      styles={customStyles}
      isSearchable={false}
      placeholder={props.placeholder}
      options={props.options}
    />
  )
}

Select.defaultProps = {
  placeholder: "",
  borderRadius: "8px",
  noShadow: false,
  theme: {
    selectedColor: "#EC5E2E",
    focusedColor: "rgb(248, 178, 156, 0.5)",
    borderColor: "rgb(248, 178, 156)",
  },
}

export default Select
