import styled, { css } from "styled-components"

export const Step = styled.div`
  padding: 0 20px;
`

export const StepTitle = styled.div`
  text-align: center;
  margin-bottom: 14px;
  font-size: 40px;
  color: #662d91;
  position: relative;
  @media (max-width: 860px) {
    font-size: 34px;
  }
  ${(props: { padded?: boolean }) =>
    props.padded &&
    css`
      @media (max-width: 860px) {
        padding-top: 60px;
      }
    `}
`

export const StepInfo = styled.p`
  text-align: center;
  color: #662d91;
  font-size: 18px;
  margin-bottom: 40px;
`

export const StepContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`
