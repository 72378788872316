import ProductUnavailable from "components/pages/Shop/ProductUnavailable"
import React from "react"
import styled from "styled-components"
import {
  DrawingToolProvider
} from "@wonkystar/drawing-tool"
import FlowSteps from "../FlowSteps"
import {
  BookCreatorStateProvider,
  steps,
  Steps,
  useBookCreatorState,
} from "./index.state"
import BookPreview from "./steps/BookPreview"
import ChooseAnimal from "./steps/ChooseAnimal"
import AnimalDetails from "./steps/AnimalDetails"
import Dedication from "./steps/Dedication"

const Wrapper = styled.div`
  /* height: calc(100vh - 100px); */
  display: flex;
  flex-direction: column;
  > .flowsteps {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`

const Content = styled.div`
  /* flex: 1;
  height: 100%;
  overflow: auto; */
`
const BookCreator = () => {
  const {
    data,
    currentStep,
    setCurrentStep,
    available,
    ChangeAnimalModal,
    openChangeAnimalModal,
  } = useBookCreatorState()

  if (!available) return <ProductUnavailable />

  return (
    <Wrapper>
      {ChangeAnimalModal || null}
      <FlowSteps
        steps={steps.map(step => {
          if (step.id === Steps.CHOOSE_ANIMAL && data.animalImg) {
            return {
              ...step,
              checked: true,
              onClick: () => {
                openChangeAnimalModal()
              },
            }
          }
          if (step.id === Steps.ANIMAL_NAME && data.animalName) {
            return { ...step, checked: true, subTitle: data.animalName }
          }
          if (step.id === Steps.FAVOURITE_SKILL && data.favouriteSkill) {
            return { ...step, checked: true, subTitle: data.favouriteSkill }
          }
          if (step.id === Steps.ADD_DEDICATION && data.customMessage) {
            return { ...step, checked: true }
          }
          return step
        })}
        onStepChange={id => setCurrentStep(id)}
        selectedStep={currentStep}
      />
      <Content>
        {currentStep === Steps.CHOOSE_ANIMAL && <ChooseAnimal />}
        {currentStep === Steps.ANIMAL_NAME && <AnimalDetails />}
        {currentStep === Steps.ADD_DEDICATION && <Dedication />}
        {currentStep === Steps.PREVIEW && <BookPreview />}
      </Content>
    </Wrapper>
  )
}

const DrawingToolWrapper = () => <DrawingToolProvider>
  <BookCreator />
</DrawingToolProvider>

export default () => {
  return (
    <BookCreatorStateProvider>
      <DrawingToolWrapper />
    </BookCreatorStateProvider>
  )
}
