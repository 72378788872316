import { useRef } from "react"
import config from "../../../../../../config"

interface IProps {
  username: string
  favouriteSkill: string
  animalName: string
  animalImg: string
  customMessage: string
}

const cropCanvas = (
  canvas: any,
  x: number,
  y: number,
  width: number,
  height: number
) => {
  // create a temp canvas
  const newCanvas = document.createElement("canvas")
  // set its dimensions
  newCanvas.width = width
  newCanvas.height = height
  // draw the canvas in the new resized temp canvas
  newCanvas
    .getContext("2d")!
    .drawImage(canvas, x, y, width, height, 0, 0, width, height)
  return newCanvas
}

export default (props: IProps) => {
  const CACHED_SPREADS = useRef<any>({})

  const fetchCover = async () => {
    if (CACHED_SPREADS.current.cover) return CACHED_SPREADS.current.cover
    const data = await fetch(
      `${config.bookGeneratorEndpoint}/escape-from-conform-prison/cover/preview`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: props.username,
          favouriteSkill: props.favouriteSkill,
          animalName: props.animalName,
          animalImg: props.animalImg,
          customMessage: props.customMessage,
        }),
      }
    )
    const pdf = await data.blob()
    const arrayBuffer = await pdf.arrayBuffer()
    CACHED_SPREADS.current.cover = arrayBuffer
    return arrayBuffer
  }

  const fetchSpread = async (spread: "cover" | number[]) => {
    if (spread === "cover") return fetchCover()

    if (CACHED_SPREADS.current[spread.join("-")])
      return CACHED_SPREADS.current[spread.join("-")]
    const data = await fetch(
      `${config.bookGeneratorEndpoint}/escape-from-conform-prison/preview`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keepPages: spread,
          username: props.username,
          favouriteSkill: props.favouriteSkill,
          animalName: props.animalName,
          animalImg: props.animalImg,
          customMessage: props.customMessage,
        }),
      }
    )
    const pdf = await data.blob()
    const arrayBuffer = await pdf.arrayBuffer()
    CACHED_SPREADS.current[spread.join("-")] = arrayBuffer
    return arrayBuffer
  }

  const downloadSpread = page => {
    const pdfCanvas: HTMLCanvasElement | null = document.querySelector(
      ".react-pdf__Page__canvas"
    )
    if (!pdfCanvas) return null
    const canvas: HTMLCanvasElement = cropCanvas(
      pdfCanvas,
      20,
      20,
      pdfCanvas.width - 40,
      pdfCanvas.height - 40
    )

    const MIME_TYPE = "image/png"

    const imgURL = canvas.toDataURL(MIME_TYPE)
    const dlLink: HTMLLinkElement | null =
      document.querySelector("#download-spread")
    if (!dlLink) return
    const filename = `escape-from-conform-prison-${props.username.replace(
      / /g,
      "_"
    )}-${props.animalName.replace(/ /g, "_")}-spread-${page}`
    // @ts-ignore
    dlLink.download = filename
    dlLink.href = imgURL
    dlLink.dataset.downloadurl = [MIME_TYPE, filename, dlLink.href].join(":")
  }

  return {
    fetchCover,
    fetchSpread,
    downloadSpread,
  }
}
